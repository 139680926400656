import React from 'react'
import PropTypes from 'prop-types'

import { Para } from 'styled';

const AirfieldInfo = ({ localeAirfieldProps, airfieldDetails, airfieldName, language }) => {
  const checkFrenchLanguage = process.env.LANG_CODE || language === "fr-fr"
  const runwayLength = checkFrenchLanguage ? Math.floor(airfieldDetails.runway_distance * 0.3048) : airfieldDetails.runway_distance;

  const countryName = airfieldDetails.country_name;
  const cityName = airfieldDetails.city_name;
  const suitableAircraft = airfieldDetails.suitability.join(', ');

  const airfieldDescription = localeAirfieldProps.generic_airfield_description.text
    .replace('{{airfield}}', airfieldName)
    .replace('{{city_name}}', cityName)
    .replace('{{country}}', countryName)
    .replace('{{runway_distance}}', runwayLength)
    .replace('{{aircraft_list}}', suitableAircraft);

  return (
    <Para
      fontSize="14px" 
      fontWeight="400"
      fontColor='rgb(70, 52, 78)'
    >
      {airfieldDescription}
    </Para>
  )
}

AirfieldInfo.propTypes = {
  airfieldDetails: PropTypes.object, 
  airfieldName: PropTypes.string,
  language: PropTypes.string,
  localeAirfieldProps: PropTypes.object
}

export default AirfieldInfo
